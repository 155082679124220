import { ThemeProvider } from '@mui/material';
import { GoogleAnalytics, GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
// import * as segment from '@segment/snippet';
import { SessionProvider } from 'next-auth/react';
import '../styles/globals.css';
import Theme from '../styles/muiTheme.js';

// router loading
import { SG_EVENT_NAMES } from '@/utils/tools/segment/eventNames';
// import { trackPageViewed } from '@/utils/tools/segment/tracking';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';

export default function App({ Component, pageProps }) {
   const router = useRouter();
   const getLayout = Component.getLayout || ((page) => page);

   NProgress.configure({ showSpinner: false });

   useEffect(() => {
      const handler = (url, param) => {
         if (param?.shallow) {
            // trackPageViewed(url);
            sendGTMEvent({ event: SG_EVENT_NAMES.PAGE_VIEWED, value: url });
         }
         if (!param?.shallow) NProgress.start();
      };
      router.events.on('routeChangeStart', handler);
      return () => router.events.off('routeChangeStart', handler);
   }, [router.events]);

   useEffect(() => {
      const handler = (url) => {
         NProgress.done();
         // trackPageViewed(url);
         sendGTMEvent({ event: SG_EVENT_NAMES.PAGE_VIEWED, value: url });
      };

      router.events.on('routeChangeComplete', handler);
      return () => router.events.off('routeChangeComplete', handler);
   }, [router.events]);

   return (
      <>
         <SWRConfig
            value={{
               fetcher: (resource, init) =>
                  fetch(resource, init).then((res) => {
                     if (!res.ok) {
                        const error = new Error('An error occurred while fetching the data.');
                        // get error message from body or default to response status
                        error.info = res.json();
                        error.status = res.status;
                        throw error;
                     }
                     return res.json();
                  }),
               // refreshInterval: 5000,
            }}
         >
            <SessionProvider>
               <ThemeProvider theme={Theme}>{getLayout(<Component {...pageProps} />)}</ThemeProvider>
            </SessionProvider>
         </SWRConfig>
         <GoogleTagManager gtmId={'GTM-PXV5KZZ'} />
         <GoogleAnalytics gaId="G-6TJZ0TBTMG" />
      </>
   );
}
